import { Routes } from '@angular/router';
import { AboutUsComponent } from './components/about-us/about-us.component';
import { SellerComponent } from './components/seller/seller.component';
import { AccessibilityAppsComponent } from './components/Apps/accessibility-apps/accessibility-apps.component';
import { CommonComponent } from './components/common/common.component';

export const AppRoutes: Routes = [

  
  {
    path: '',
    component: CommonComponent,
    loadChildren: () =>
      import(
        /* webpackChunkName: "product-details-routes" */ './routes.module'
      ).then((x) => x.AppRoutes),
  },
  {
    path: 'accessibility-apps',
    title: 'accessibility apps',
     component: AccessibilityAppsComponent
  },
  
];
