import { Inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, defer, map, of, switchMap, tap } from 'rxjs';

import { cartActions } from './cart.actions';
import { CartService } from '../services';
import { authActions } from '@ea/auth';
import { CartResponse, getDefaultCart } from '../models';
import { ProductService, ToastService } from '@ea/services';
import { LocalStorageService } from '@ea/services';
import { error } from 'console';
import { FreshPaintService } from 'src/app/services/shared/freshPaint/fresh-paint.service';
declare var freshpaint: any;
@Injectable()
export class CartEffects {
  reload$ = createEffect(() =>
    this.actions$.pipe(
      ofType(authActions.set, authActions.loginSuccess),
      switchMap((userState) =>
        defer(() => {
          if (userState.user.customId) {
            const result = cartActions.find({
              customId: userState.user.customId,
            });
            return of(result);
          } else if (this.storageService.getItem('cartId')) {
            const result = cartActions.get({
              cartId: this.storageService.getItem('cartId'),
              isApplyPromo: false,
            });

            return of(result);
          } else {
            return of(cartActions.set(getDefaultCart('')));
          }
        })
      )
    )
  );

  cleanLocally$ = createEffect(() =>
    this.actions$.pipe(
      ofType(authActions.logout),
      switchMap(() => defer(() => of(cartActions.set(getDefaultCart()))))
    )
  );

  find$ = createEffect(() =>
    this.actions$.pipe(
      ofType(cartActions.find),
      switchMap(({ customId }) =>
        this.cartService
          .find(customId ?? '')
          .pipe(
            map((cart) =>
              cartActions.set(cart.carts[0] ?? getDefaultCart(customId))
            )
          )
      )
    )
  );

  get$ = createEffect(() =>
    this.actions$.pipe(
      ofType(cartActions.get),
      switchMap(({ cartId, isApplyPromo }) =>
        this.cartService.get(cartId ?? '').pipe(
          map((cart: CartResponse) => {
            if (isApplyPromo) {
              var items = cart.items?.map((item) => ({
                item_id: item.sku, // Assuming each item has an id
                item_name: item.extra.parentName.value, // Assuming each item has a name
                item_category:
                  item.extra.breadcrumbsString.value != null
                    ? item.extra.breadcrumbsString.value.split('>')[0].trimEnd()
                    : '', // Assuming each item has a category
                price: item.unitPrice.sale, // Assuming each item has a price
                currency: 'GBP',
                item_list_name:
                  item.extra.breadcrumbsString.value != null
                    ? item.extra.breadcrumbsString.value.split('>')[0].trimEnd()
                    : '',
                item_brand: item.attributes.find((x) => x.name == 'brand')
                  ?.value,
                vendor_name: item.attributes.find(
                  (x) => x.name == 'vendor_name'
                )?.value,
                quantity: item.quantity,
              }));

              this.FB.sendEvent('select_promotion', {
                items: items,
                promotion_name: cart.allPromosApplied[0].promoCode,
              });
            }
            return cartActions.set(
              !cart || cart.items?.length == 0 ? getDefaultCart() : cart
            );
          }), // Set the cart or fallback to default
          catchError(() => of(cartActions.set(getDefaultCart()))) // On error, set the default cart
        )
      )
    )
  );

  applyPromoCode$ = createEffect(() =>
    this.actions$.pipe(
      ofType(cartActions.applyPromoCode),
      switchMap((request) =>
        this.cartService.applyPromoCode(request.cartId, request.promoCode).pipe(
          switchMap(() => {
            this.cartService.toast = {
              message: 'Promotion applied successfully!',
              type: 'success',
              duration: 6000,
              isSmall: true,
            };

            return of(
              cartActions.get({ cartId: request.cartId, isApplyPromo: true })
            );
          }),
          catchError((e) => {
            this.cartService.toast = {
              message: e.error,
              type: 'error',
              duration: 6000,
              isSmall: true,
            };
            this.FB.sendEvent('error', {
              Page_type: 'Cart',
              Page_name: 'my Cart',
              Error_type: 'bad request',
              Error_msg: e.error,
            });

            return of(
              cartActions.get({ cartId: request.cartId, isApplyPromo: false })
            );
          })
        )
      )
    )
  );

  removePromoCode$ = createEffect(() =>
    this.actions$.pipe(
      ofType(cartActions.removePromoCode),
      switchMap((request) =>
        this.cartService
          .removePromoCode(request.cartId, request.promoCode)
          .pipe(
            switchMap(() => {
              return of(
                cartActions.get({ cartId: request.cartId, isApplyPromo: false })
              );
            }),
            catchError((e) => {
              this.toasterService.show(e.error, 'error');
              return of(
                cartActions.get({ cartId: request.cartId, isApplyPromo: false })
              );
            })
          )
      )
    )
  );

  addProduct$ = createEffect(() =>
    this.actions$.pipe(
      ofType(cartActions.addProduct),
      switchMap((request) =>
        this.cartService.addItem(request).pipe(
          switchMap((response) => {
            if (response.carts) {
              // Check if items exist in the cart
              if (
                response.carts[0].items &&
                response.carts[0].items.length > 0
              ) {
                const addedItemTitle =
                  response.carts[0].items[0].extra.parentName.value;
                this.storageService.setItem('cartId', response.carts[0].cartId);
                this.storageService.setItem(
                  'meta-name',
                  response.carts[0].metadata.name
                );
                // this.toasterService.show(
                //   `You have successfully added ${addedItemTitle} in your basket!`,
                //   'success'
                // );
                this.productService.toast = {
                  message: `You have successfully added ${addedItemTitle} in your basket!`,
                  duration:
                    6000 +
                    Math.ceil(
                      `You have successfully added ${addedItemTitle} in your basket!`.split(
                        ' '
                      ).length / 120
                    ) *
                      100,
                  type: 'success',
                };
              }

              return of(cartActions.set(response.carts[0]));
            } else {
              return of(
                cartActions.get({ cartId: request.cartId, isApplyPromo: false })
              );
            }
          }),
          catchError((e) => {
            if (e.error == 'Out of Stock') {
              const addedItemTitle = request.name;
              this.FB.sendEvent('error', {
                Page_type: 'PDP',
                Page_name: addedItemTitle,
                Error_type: 'bad request',
                Error_msg: 'Out of Stock',
              });
              this.productService.toast = {
                message: `Out of Stock`,
                duration:
                  6000 +
                  Math.ceil(`Out of Stock`.split(' ').length / 120) * 100,
                type: 'warning',
              };
            } else {
              const addedItemTitle = request.name;
              this.FB.sendEvent('error', {
                Page_type: 'PDP',
                Page_name: addedItemTitle,
                Error_type: 'server error',
                Error_msg: 'something went wrong',
              });
              this.productService.toast = {
                message: `something went wrong`,
                duration:
                  6000 +
                  Math.ceil(`something went wrong`.split(' ').length / 120) *
                    100,
                type: 'error',
              };
            }
            return of(
              cartActions.get({ cartId: request.cartId, isApplyPromo: false })
            );
          })
        )
      )
    )
  );

  // updateProduct$ = createEffect(() =>
  //   this.actions$.pipe(
  //     ofType(cartActions.updateProduct),
  //     switchMap((params) =>
  //       this.cartService.updateItem(params.cartId, params.request).pipe(
  //         switchMap((response) => {
  //           if (response && response.cartId) {
  //             this.toasterService.show(
  //               `You have successfully updated an item in your cart!`
  //             );
  //             return of(cartActions.set(response));
  //           } else {
  //             return of(cartActions.get({ cartId: params.cartId }));
  //           }
  //         })
  //       )
  //     )
  //   )
  // );

  updateProduct$ = createEffect(() =>
    this.actions$.pipe(
      ofType(cartActions.updateProduct),
      switchMap((params) =>
        this.cartService.updateItem(params.cartId, params.request).pipe(
          switchMap((response) => {
            if (response && response.cartId) {
              // Check if items exist in the updated cart
              if (response.items && response.items.length > 0) {
                const updatedItemTitle = response.items[0].title;
                // this.toasterService.show(
                //   `You have successfully updated ${updatedItemTitle} in your basket!`,
                //   'success'
                // );
                this.productService.toast = {
                  message: `You have successfully updated ${updatedItemTitle} in your basket!`,
                  duration:
                    6000 +
                    Math.ceil(
                      `You have successfully updated ${updatedItemTitle} in your basket!`.split(
                        ' '
                      ).length / 120
                    ) *
                      100,
                  type: 'success',
                };
              }

              return of(cartActions.set(response));
            } else {
              return of(
                cartActions.get({ cartId: params.cartId, isApplyPromo: false })
              );
            }
          }),
          catchError((x) => {
            this.toasterService.show(
              `Not enough stock available for this item`,
              'warning'
            );
            return of(
              cartActions.get({ cartId: params.cartId, isApplyPromo: false })
            );
          })
        )
      )
    )
  );

  updateItemShippingMethod$ = createEffect(() =>
    this.actions$.pipe(
      ofType(cartActions.updateItemShippingMethod),
      switchMap((params) =>
        this.cartService
          .setAddressToItem(
            params.cartId,
            params.address,
            params.shipMethod,
            params.items
          )
          .pipe(
            switchMap((response) => {
              return of(
                cartActions.get({
                  cartId: response.cartId,
                  isApplyPromo: false,
                })
              );
            })
          )
      )
    )
  );

  updateItemFailed$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(cartActions.updateItemFailed),
        tap(() => {
          // this.toastrService.success('Failed updating the item in your cart!');
          this.toasterService.show(
            `Failed updating the item in your basket!`,
            'error'
          );
        })
      ),
    { dispatch: false }
  );

  removeProduct$ = createEffect(() =>
    this.actions$.pipe(
      ofType(cartActions.removeProduct),
      switchMap((request) =>
        this.cartService.removeItem(request.cartId, request.lineItemId).pipe(
          switchMap((response) => {
            if (response) {
              return of(
                cartActions.get({ cartId: request.cartId, isApplyPromo: false })
              );
            } else {
              return of(cartActions.updateItemFailed());
            }
          })
        )
      )
    )
  );

  loginWithCartItems$ = createEffect(() =>
    this.actions$.pipe(
      ofType(cartActions.loginWithCartItems),
      switchMap((request) =>
        this.cartService
          .addItemsToUserCart(request.cartId, request.customId)
          .pipe(
            switchMap((r) => {
              const t = cartActions.find({
                customId: request.customId,
              });
              return of(t);
            })
          )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private cartService: CartService,
    private toasterService: ToastService,
    private storageService: LocalStorageService,
    private productService: ProductService,
    private readonly FB: FreshPaintService
  ) {}
}
