import { CommonModule, isPlatformBrowser } from '@angular/common';
import {
  AfterViewInit,
  Component,
  HostListener,
  Inject,
  OnInit,
  PLATFORM_ID,
} from '@angular/core';
import {
  ActivatedRoute,
  NavigationEnd,
  Router,
  RouterLinkWithHref,
  RouterOutlet,
} from '@angular/router';
import {
  FooterComponent,
  MastheadComponent,
  NavComponent,
  SearchBarComponent,
} from './components';
import { LoadingBarModule } from '@ngx-loading-bar/core';
import { LoadingBarRouterModule } from '@ngx-loading-bar/router';
import { TranslateModule } from '@ngx-translate/core';
import { RouterBreadcrumbsComponent } from './components/breadcrumbs/router-breadcrumb/router-breadcrumb/router-breadcrumb.component';
import { ToasterComponent } from '@ea/components';
import { FilteredImageService } from './services/shared/filter-image/filtered-image.service';
import { CategoryService, LocalStorageService } from './services';
import { NavCategoryItem } from './models';
import { Observable, Subscription, of } from 'rxjs';
import { filter } from 'rxjs/operators';

import { IntercomService, SignalRService } from '@ea/services';
import environment from '../environments/environment';
import { CookieService } from '@ea/auth';
import { DomSanitizer } from '@angular/platform-browser';
import { MetaService } from './services/shared/MetaServices/meta.service';
import { NgcCookieConsentService } from 'ngx-cookieconsent';
import { FreshPaintService } from './services/shared/freshPaint/fresh-paint.service';
declare var freshpaint: any;

@Component({
  selector: 'ea-root',
  standalone: true,
  imports: [
    CommonModule,
    FooterComponent,
    LoadingBarModule,
    LoadingBarRouterModule,
    MastheadComponent,
    NavComponent,
    SearchBarComponent,
    RouterOutlet,
    RouterBreadcrumbsComponent,
    RouterLinkWithHref,
    ToasterComponent,
    TranslateModule,
  ],

  providers: [],
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, AfterViewInit {
  title = 'enableAll';
  categories: Observable<NavCategoryItem[]> = of();
  subscriptions: Array<Subscription> = [];
  navigation: any;
  isBrowser: any;
  isSticky: boolean = false;
  private routerSubscription!: Subscription;
  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    private router: Router,
    private filterImageService: FilteredImageService,
    private storage: LocalStorageService,
    private categoryService: CategoryService,
    private intercomService: IntercomService,
    private rtcService: SignalRService,
    private cookies: CookieService,
    public readonly sanitizer: DomSanitizer,
    private metaService: MetaService,
    private route: ActivatedRoute,
    private FP: FreshPaintService,
    private ccService: NgcCookieConsentService //don't remove service, it used for cookie banner
  ) {
    this.isBrowser = isPlatformBrowser(this.platformId);

    this.subscriptions.push(
      this.router.events
        .pipe(filter((routerEvent) => routerEvent instanceof NavigationEnd))
        .subscribe((event: any) => {
          let baseurl =
            environment.base == 'uk'
              ? environment.ukDomainUrl
              : environment.usDomainUrl;
          const url = `${baseurl}${event.urlAfterRedirects}`;
          this.metaService.updateAlternateLink(url);
          if (this.isBrowser) window.scrollTo(0, 0);
        })
    );
  }

  @HostListener('window:scroll', ['$event'])
  checkScroll() {
    this.isSticky = window.scrollY >= 200;
  }

  ngOnInit(): void {
    this.route.queryParams.subscribe((params) => {
      if(params['gclid']){
      this.FP.gclid = params['gclid'];
      }
    });
    this.categories = this.categoryService.list();
    if (isPlatformBrowser(this.platformId)) {
      let connectionUrl = `${
        environment.serverUrl
      }/loginHub?uniqueIdentifier=${this.cookies
        .get('_snrs_uuid')
        ?.toString()}`;

      freshpaint.identify(this.cookies.get('_snrs_uuid')?.toString());

      this.rtcService.hubConnection =
        this.rtcService.startConnection(connectionUrl);
    }

    const searchFilter = this.storage.getItem('searchFilter');
    if (searchFilter) {
      this.filterImageService.searchFilter.next(searchFilter);
    }
  }

  ngAfterViewInit() {
    if (isPlatformBrowser(this.platformId)) {
      this.intercomService.loadIntercomScript();
      this.intercomService.bootIntercom();
      if (this.storage.getItem('uuid') && this.storage.getItem('customId')) {
        this.intercomService.setIntercomUser(
          this.storage.getItem('customId'),
          this.storage.getItem('uuid')
        );
        this.intercomService.customId = this.storage.getItem('customId');
        this.intercomService.uuid = this.storage.getItem('uuid');
      }
    }
  }

  skipTo(element: HTMLElement) {
    element.scrollIntoView();
    this.focusWithin(element);
  }

  private focusWithin(element?: HTMLElement) {
    return this.findNextFocusableElement(element)?.focus();
  }

  private findNextFocusableElement(element?: HTMLElement) {
    return element?.querySelector<HTMLElement>(
      'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])'
    );
  }
}
