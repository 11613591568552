import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { RouterOutlet } from '@angular/router';

@Component({
  selector: 'app-accessibility-apps',
  standalone: true,
  imports: [CommonModule,     RouterOutlet],
  templateUrl: './accessibility-apps.component.html',
  styleUrl: './accessibility-apps.component.scss'
})
export class AccessibilityAppsComponent {

}
